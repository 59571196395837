import React, { useState } from "react";
import Options from "../Compartido/options";
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import axios from 'axios';

const Content = ({ lang, contacto }) => {

    const { t } = useTranslation();

    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [telefon, setTelefon] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [consentimiento, setConsentimiento] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        if (nombre === "" || telefon === "" || email === "" || !consentimiento) {
            let mensajeerror = "";
            if (nombre === "") mensajeerror += `${t("Nombre")}<br/>`;
            if (telefon === "") mensajeerror += `${t('Teéfono')}<br/>`;
            if (email === "") mensajeerror += `${t('Email')}<br/>`;
            if (!consentimiento) mensajeerror += `${t("consentimiento")}<br/>`;
            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
            });
        } else {
            let elnombre = nombre;
            let eltelefon = telefon;
            let elmail = email;
            let elmensaje = mensaje;
            let elidioma = document.getElementById("idioma").value;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                yourname: elnombre,
                contactMessage: elmensaje,
                Phone: eltelefon,
                contactEmail: elmail,
                lang: elidioma
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    };

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div id="Content">
            <div className="clearfix content_wrapper">
                <div className="sections_group">
                    <div className="entry-content">

                        <Options
                            preidioma={preidioma}
                        />

                        <div className="section mcb-section no-margin-h" style={{ paddingTop: "60px", paddingBottom: "80px" }}>
                            <div className="section mcb-section T60B40">
                                <div className="clearfix column_attr align_center">
                                    <h6 className="landing4-heading grey positionContainer text-uppercase">{t("Contacto")}</h6>
                                    <hr className="no_line margin15Auto" />
                                </div>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="column mcb-column one-second column_column" dangerouslySetInnerHTML={{ __html: contacto.ladesc }}></div>

                                    <div className="column mcb-column one-second column_column">
                                        <div id="map-and-friends">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.7821218952377!2d2.147034576617257!3d41.400537795178366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a29f4e09eb99%3A0x36d7718a9e496532!2sCarrer%20dels%20Madrazo%2C%2027%2C%20Sarri%C3%A0-Sant%20Gervasi%2C%2008006%20Barcelona!5e0!3m2!1sen!2ses!4v1743508611680!5m2!1sen!2ses" width="600" height="450" className="border0" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section mcb-section" style={{ paddingTop: "60px", paddingBottom: "80px", backgroundColor: "#f9f9f9" }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="clearfix wrap mcb-wrap one valign-top">
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column one column_column">
                                                <div className="clearfix column_attr">
                                                    <h3>{t("Envia un mensaje")}</h3>
                                                    <hr className="no_line" style={{ margin: "0 auto 15px" }} />
                                                    <div id="contactWrapper">
                                                        <form id="contactForm" action="/enviacontacto" onSubmit={handleSubmit}>
                                                            <input type="hidden" name="lang" id="idioma" value={lang.toString().toUpperCase()} />
                                                            <div className="column one-third">
                                                                <input placeholder={t("Nombre y apellidos") + " *"} type="text" name="name" id="name" size="40" aria-required="true" aria-invalid="false" required
                                                                    value={nombre} onChange={(e) => setNombre(e.target.value)}
                                                                />
                                                            </div>

                                                            <div className="column one-third">
                                                                <input placeholder={t("Email") + " *"} type="email" name="email" id="email" size="100" aria-required="true" aria-invalid="false" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                            </div>

                                                            <div className="column one-third">
                                                                <input placeholder={t("Teléfono") + " *"} type="text" name="phone" id="phone" size="40" aria-required="true" aria-invalid="false" value={telefon} onChange={(e) => setTelefon(e.target.value)} />

                                                            </div>
                                                            <div className="column one">
                                                                <textarea placeholder={t("Mensaje")} name="message" id="subject" style={{ width: "100%" }} rows="10" aria-invalid="false"
                                                                    value={mensaje} onChange={(e) => setMensaje(e.target.value)}
                                                                ></textarea>
                                                            </div>
                                                            <div className="column one">
                                                                <div className="g-recaptcha" data-sitekey="6LfsiDEUAAAAAL_oH_ULiiYC0znKCwytP2-S_Ann"></div>
                                                            </div>

                                                            <div className="mt-20 column one">
                                                                <input id="consentimiento" type="checkbox" name="consentimiento" checked={consentimiento} onChange={(e) => setConsentimiento(e.target.checked)} />{t("He leido y doy")} <a href={"/" + preidioma + t('consentimiento-formulario')}> {t('consentimiento')} </a> {t("para el tratamiento de datos")}
                                                            </div>

                                                            <div className="column one">
                                                                <input type="submit" value={t("Enviar")} id="submit" />
                                                                <p>{t("Todos los campos * son obligatorios")}</p>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
