import React, { useState } from "react";

import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2';
import axios from 'axios';


const FormularioContacto = ({ lang, config }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [tema, setTema] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [consentimiento, setConsentimiento] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        var mensajeerror = "";

        if (nombre === "" || telefono === "" || email === "" || mensaje === "" || tema === "" || !consentimiento) {

            if (nombre === "") mensajeerror += `${t("Nombre")}<br/>`;
            if (telefono === "") mensajeerror += `${t('Teléfono')}<br/>`;
            if (email === "") mensajeerror += `${t('Email')}<br/>`;
            if (mensaje === "") mensajeerror += `${t('Mensaje')}<br/>`;
            if (tema === "") mensajeerror += `${t('Tema')}<br/>`;
            if (!consentimiento) mensajeerror += `${t("consentimiento")}<br/>`;

            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeClick: false
            });
        } else {
            let elnombre = nombre;
            let eltelefono = telefono;
            let elmail = email;
            let elmensaje = mensaje;
            let eltema = tema;
            let elidioma = document.getElementById("idioma").value;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                yourname: elnombre,
                Phone: eltelefono,
                contactEmail: elmail,
                contactMessage: eltema + "<br/>" + elmensaje,

                lang: elidioma
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    };



    return (
        <section>
            <div className="container">
                <div className="row noticia-individual noticia-individual-descripcio-llarga">
                    <h2 className="mb-2 noticia-individual">{t("Contacte_con_nosotros")}</h2>
                    <p className="mb-3 noticia-individual">{t("Nos encanta que nos digan algo. Póngase en contacto con nosotros.")}</p>
                    <form className="noticia-individual" id="contactForm" action="/enviacontacto" onSubmit={handleSubmit}>
                        <input type="hidden" name="lang" id="idioma" value={lang.toString().toUpperCase()} />
                        <div className="row">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Su nombre")} name="yourname" required="" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                            </div>

                            <div className="form-group">
                                <input type="email" className="form-control" placeholder={t("Email")} name="contactEmail" required="" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Su tema")} name="subject" required=""
                                    value={tema} onChange={(e) => setTema(e.target.value)} />
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Su teléfono")} name="Phone" required=""
                                    value={telefono} onChange={(e) => setTelefono(e.target.value)}
                                />
                            </div>

                            <div className="form-group">
                                <textarea className="form-control" rows="9" placeholder={t("Mensaje")} name="contactMessage" required="" value={mensaje} onChange={(e) => setMensaje(e.target.value)}></textarea>
                            </div>

                            <div className="form-group">
                                <label>
                                    <input type="checkbox" value="acepto" name="acepto" required="" checked={consentimiento} onChange={(e) => setConsentimiento(e.target.checked)} /> <a href={"/" + preidioma + t("terminos")} target="_blank"> {t("Acepto los términos y condiciones")}</a>
                                </label>
                            </div>
                            <div className="pull-right">
                                <input type="submit" className="btn btn-danger" value={t("Enviar")} style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }} />
                            </div>

                            <div className="g-recaptcha" data-sitekey="6LfsiDEUAAAAAL_oH_ULiiYC0znKCwytP2-S_Ann">
                                <div className="tamcaptcha">
                                    <iframe title="reCAPTCHA" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LfsiDEUAAAAAL_oH_ULiiYC0znKCwytP2-S_Ann&amp;co=aHR0cHM6Ly93d3cubWFnbm9hcGFydG1lbnRzLmNvbTo0NDM.&amp;hl=es&amp;v=khH7Ei3klcvfRI74FvDcfuOo&amp;size=normal&amp;cb=vigxn6vv0sml" width="304" height="78" role="presentation" name="a-1bz1kx8gmk2p" frameBorder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation">
                                    </iframe>
                                </div>
                                <textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response tamtextcaptcha"></textarea>
                            </div>
                            <iframe className="noshow"></iframe>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default FormularioContacto;
